import { useFetch } from 'hooks/useFetch';
import {
    Card,
    CardHeader,
    Table,
    Row,
    Spinner,
    Input,
    InputGroup,
    FormGroup
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';

export default function TopRiderForCategory() {
    const [category, setCategory] = useState({ id: '', name: '' });
    const [payload, setPayload] = useState('');
    const [show, setShow] = useState({ id: '', name: '' });
    const { data: topRider, isPending: teamIsPending } = useFetch(process.env.REACT_APP_NODE_SERVER + '/api/results/getTotalRiderPointsByCategory/' + category.id+payload);
    const { data: categories, isPending } = useFetch(process.env.REACT_APP_NODE_SERVER + '/api/category/getAll');
    const { data:shows, isPending:showPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/show/getAllShowsAdmin?sortBy=-1');
    
        useEffect(()=>{
          if(error){
            NotificationManager.error(error, 'Error', 3000);
          }
        }, [error])
    
    const handleCategoryChange = (e) => {
        const selectedValue = e.target.value;
        const [categoryId, categoryName] = selectedValue.split(':');

        setCategory({ id: categoryId, name: categoryName });

    };

    const handleShowChange = (e) => {
        const selectedValue = e.target.value;
        const [showId, showName] = selectedValue.split(':');
        setShow({ id: showId, name: showName });
        setPayload('?show_id='+showId);
    };

    return (
        <div className="container pt-8">
            <div className='row'>
                <div className="col-sm-6">
                <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input
                            placeholder="Shows"
                            type="select"
                            value={show.id+':'+show.name}
                            onChange={handleShowChange}
                        >
                            <option value="">Select Show</option>
                                {shows && shows.shows.map((show) => {
                                    return(
                                <option value={`${show.show_id}:${show.show_name}`} key={show.show_id}>{show.show_name}</option>
                            )})}
                        </Input>
                    </InputGroup>
                </FormGroup>
                </div>
                <div className="col-sm-6">
                <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input
                            placeholder="Category"
                            type="select"
                                value={`${category.id}:${category.name}`}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Select Category</option>
                            {categories && categories.categories.map((category) => (
                                <option value={`${category.category_id}:${category.category_name}`} key={category.category_id}>{category.category_name}</option>
                            ))}
                        </Input>
                    </InputGroup>
                </FormGroup>
                </div>
                <div className="col-sm-3"></div>
            </div>
            {topRider && topRider.totalRiderPointsByCategory.length === 0 && <p className='text-danger text-center'>The results are not published yet!</p>}
            {topRider && topRider.totalRiderPointsByCategory.length > 0 && <Row className='my-5'>
                <div className="col">
                    {<Card className="shadow">
                        <CardHeader className="border-0">
                            <h3 className="mb-0">Top Rider - Category {category.name}</h3>
                        </CardHeader>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th />
                                    <th scope="col">Rider Name</th>
                                    <th scope="col">Team Name</th>
                                    <th scope="col">Rider Point</th>
                                </tr>
                            </thead>
                            <tbody>
                                {topRider && topRider.totalRiderPointsByCategory.map((rider, index) => (<tr key={index}>
                                    <th>{index + 1}</th>
                                    <th scope="row">
                                        {rider.rider_name}
                                    </th>
                                    <th>{rider.team_name}</th>
                                    <th>{!['9','11','12'].includes(category.id) ?  rider.total_rider_points : '-'}</th>
                                </tr>))}
                                {teamIsPending && <tr className='d-flex justify-content-center align-items-center p-4'><td><Spinner color="primary" style={{ height: '3rem', width: '3rem' }}> Loading...</Spinner></td></tr>}
                            </tbody>
                        </Table>
                    </Card>}
                </div>
            </Row>}
        </div>
    )
}
