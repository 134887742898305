import { useFetch } from 'hooks/useFetch';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Row,
    Spinner,
    Button,
  } from "reactstrap";
import { usePost } from 'hooks/usePost';
export default function EnrollmentDetails() {
    const {showId,eventId,subEventId,eventCollectionId} = useParams();
    const {isPending,error,data} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/event-collection/getEventCollectionById/'+eventCollectionId);
    const {data:show} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/show/getById/'+showId);
    const {data:event} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/event/getEventById/'+eventId);
    const {data:subEvent} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/subevent/getSubeventById/'+subEventId);
    const [listType, setListType] = useState('approved');
    const [waitlist, setWaitlist] = useState([]);
    
    const [categoryIds,setCategoryIds] = useState(null);
    const [enrollments,setEnrollments] = useState(null);
    const [isLoading,setIsloading] = useState(false);
    const {postData,resData,postError} = usePost();

    useEffect(()=>{
        if(data && data.status === 200){
            setCategoryIds(JSON.parse(data.eventCollection.category_ids))
        }
        if(error){
            NotificationManager.error(error, 'Error', 3000);
        }
    },[data,error])
    
    useEffect(()=>{
      if(resData && resData.status === 200){
        NotificationManager.success('Rider order changed successfully!', 'Error', 3000);
        window.location.reload();
      }
      if(postError){
          NotificationManager.error(postError, 'Error', 3000);
      }
  },[resData,postError])
    useEffect(()=>{
        if(categoryIds !== null){
            fetchEnrollments()
        }
    },[categoryIds])
    const fetchEnrollments = async() => {
        try{    
            setIsloading(true);
            const response = await fetch(`${process.env.REACT_APP_NODE_SERVER}/api/enrollment/getEnrollmentsForEvents/${eventId}/${subEventId}/${showId}/${eventCollectionId}/?category_ids=${categoryIds?.join(',')}`);
            if(!response.ok){
                throw new Error("Something went wrong!")
            }
            const data = await response.json();
            if(data.status !== 200){
                throw new Error("Cannot get data!")
            }
            //console.log(data.groupedEnrollments)
            setEnrollments(data.groupedEnrollments)
          setIsloading(false);
        }catch(err){
            NotificationManager.error(err.message, 'Error', 3000);
        }
    }

    const handleRiderOrder = (enrollmentId,orderNumber) =>{
      postData(process.env.REACT_APP_NODE_SERVER+"/api/enrollment/update-rider-order/"+enrollmentId,{"rider_order": orderNumber},"PUT")
  }

  const getAllWaitlist = async () => {
    await fetch(
      `${process.env.REACT_APP_NODE_SERVER}/api/enrollment/getAllWaitlistedEnrollments/${eventId}/${subEventId}/${showId}/${eventCollectionId}/?category_ids=${categoryIds?.join(
        ","
      )}`
    ).then(async (res) => {
      const data = await res.json();
      if (!res.ok) {
        throw new Error("Something went wrong!")
      } else {
        setWaitlist(data?.groupedEnrollments)
      }
    }).catch((err) => { 
      NotificationManager.error(err.message, 'Error', 3000);
    });
  }
  
  const toggleList = (type) => {
    setListType(type);
    if (type === 'approved') {
      fetchEnrollments()
    } else {
      getAllWaitlist()
    }
  };

  const approveEnrolled = async (enrollmentId) => {
    setIsloading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_SERVER}/api/enrollment/approveWaitlisted/${enrollmentId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
        }
      );
      const data = await response.json();
      if (data.status == 200) {
        NotificationManager.success("Approved", "", 3000);
        setListType('approved')
        fetchEnrollments()
      } else {
        NotificationManager.error("Something went wrong !!", "Error", 3000);
      }
    } catch (error) {
      console.error("Error in postData:", error);
      throw error;
    }
    setIsloading(false)
   }

  const renderListing = (type, enrollments) => {
    return (
      <>
        {enrollments && Object.keys(enrollments).length === 0 && (
        <p className="text-warning">No Enrollments</p>
      )} 
      {enrollments &&
        Object.keys(enrollments).map((categoryId) => (
          <Row key={categoryId} className="mb-5">
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    {enrollments[categoryId][0].category_name}
                  </h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>S. NO</th>
                      <th scope="col">Rider Name</th>
                      <th scope="col">Horse Name</th>
                      <th scope="col">Team Name</th>
                      {type === 'waitlist' && <th scope="col"></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {enrollments[categoryId].map((enrollment, index) => (
                      <tr key={enrollment.enrollment_id}>
                        <th>{index + 1}</th>
                        <th scope="row">
                          {enrollment.rider_name}{" "}
                          {enrollment.hc === 1 ? "(HC)" : ""}
                        </th>
                        <td>{enrollment.name}</td>
                        <td>{enrollment.team_name}</td>
                        {type == "waitlist" && (
                          <td><Button
                          size="sm"
                            color="success"
                            type='button'
                          onClick={() => approveEnrolled(enrollment.enrollment_id)}
                        >
                          Approve
                        </Button></td>
                        )}
                      </tr>
                    ))}
                    {isPending && (
                      <tr className="d-flex justify-content-center align-items-center p-4">
                        <td>
                          <Spinner
                            color="primary"
                            style={{ height: "3rem", width: "3rem" }}
                          >
                            {" "}
                            Loading...
                          </Spinner>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    {/* Pagination and other footer content */}
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        ))}
      </>
    )
  }

    if(isPending && isLoading){
        return (
            <div className='container pt-8 text-center'>
                <NotificationContainer />
                <Spinner
                color="primary"
                style={{ height: "3rem", width: "3rem" }}
                ></Spinner>
            </div>
        )
    }else{
  return (
    <div className="container pt-8">
      <NotificationContainer />
      {show && <h2>Show Name - {show.show.show_name}</h2>}
      {event && subEvent && (
        <h3 className="pb-3">
          Enrollments for {event.event.event_name} - ({" "}
          {subEvent.subevent.subevent_name} )
        </h3>
      )}
      <Row className="ml-1">
        <Button onClick={() => toggleList('approved')} className="my-4" color={listType == 'approved' ? 'primary': 'default'} type="button">
          Approved
        </Button>
        <Button onClick={() => toggleList('waitlist')} className="my-4" color={listType !== 'approved' ? 'primary': 'default'} type="button">
          Wait list
        </Button>
      </Row>
      {( enrollments || waitlist) && listType === 'approved' ? renderListing(listType,enrollments) : renderListing(listType,waitlist)}
      
    </div>
  );
            }
        }
