import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Spinner,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  CardBody,
  Card,
  Row
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';

export default function AddDiscipline() {
  const [eventName, seteventName] = useState("");
  const [eventDesc, seteventDesc] = useState("");
  const [price, setprice] = useState("");
  const [image, setImage] = useState(null); // State to store the uploaded image
  const { postData, resData, postError, isPending } = usePost();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);

  const handleCreateShow = (e) => {
    e.preventDefault();
    if (eventName === "" || eventDesc === "" || price === "" || !image) {
      NotificationManager.error("All fields, including the image, are required!", 'Error', 3000);
      return;
    }

    // Prepare FormData to send text fields and the image
    const formData = new FormData();
    formData.append("event_name", eventName);
    formData.append("event_desc", eventDesc);
    formData.append("price", Number(price));
    formData.append("image", image); // Append the image file

    // Send the FormData using fetch (replace postData if not needed)
    fetch(process.env.REACT_APP_NODE_SERVER + '/api/event/add-event', {
      method: "POST",
      body: formData
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Failed to create discipline");
        }
        return response.json();
      })
      .then(data => {
        NotificationManager.success("Discipline Created Successfully!", 'Success', 3000);
        setIsLoader(true);
        seteventName('');
        seteventDesc('');
        setprice('');
        setImage(null);

        setTimeout(() => {
          navigate(`/admin/add-sub-discipline/${data.event_id}`);
        }, 3000);
      })
      .catch(error => {
        NotificationManager.error(error.message, 'Error', 5500);
      });
  };

  return (
    <div className="container pt-8">
      <NotificationContainer />
      {isLoader &&
        <div className="text-center">
          <Spinner color="primary" style={{ height: '3rem', width: '3rem' }}></Spinner>
        </div>}
      {!isLoader && <Row>
        <Col lg="3"></Col>
        <Col lg="6" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small><b>ADD DISCIPLINE</b></small>
              </div>
              <form onSubmit={handleCreateShow} encType="multipart/form-data">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Discipline Title"
                      type="text"
                      value={eventName}
                      onChange={(e) => seteventName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Discipline Price"
                      type="text"
                      value={price}
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Discipline Description"
                      type="textarea"
                      value={eventDesc}
                      onChange={(e) => seteventDesc(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label for="imageUpload">Upload Discipline Image</Label>
                  <Input
                    type="file"
                    id="imageUpload"
                    onChange={(e) => setImage(e.target.files[0])} // Set the uploaded file
                  />
                </FormGroup>
                <div className="text-center">
                  {!isPending && <Button className="my-4" color="primary" type="submit">
                    Submit
                  </Button>}
                  {isPending && <Button className="my-4" color="primary" disabled>
                    <Spinner size="sm" />
                    {' '}Submitting...
                  </Button>}
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3"></Col>
      </Row>}
    </div>
  );
}
