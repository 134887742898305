import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useFetch } from 'hooks/useFetch';
import { useParams } from 'react-router-dom';
import { usePost } from 'hooks/usePost';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
export default function Results() {
    const { eventId, subeventId, showId, eventCollectionId,categoryId } = useParams();
    const [isEdit,setIsEdit] = useState(false);
    const { data } = useFetch(
      `${process.env.REACT_APP_NODE_SERVER}/api/enrollment/getEnrollmentsForEventsByCategory/${eventId}/${subeventId}/${showId}/${eventCollectionId}/${categoryId}`
    );
    const [refresh , setRefresh] = useState(false)
    const {data:results} = useFetch(process.env.REACT_APP_NODE_SERVER+`/api/results/getResultsByParams?category_id=${data ?data.enrollmentsForCategory[0].category_id:''}&show_id=${data?data.enrollmentsForCategory[0].show_id:''}&event_id=${data ? data.enrollmentsForCategory[0].event_id:""}&subevent_id=${data ?data.enrollmentsForCategory[0].subevent_id:''}&refresh=${refresh}`)
    const [inputValues, setInputValues] = useState([]);
    const {postData,resData,postError} = usePost();
    const {postData:updateResults,resData:updateResultRes,postError:updateResultError} = usePost();
    const [resultsData,setResultsData] = useState(null);
    const confinedArray = ['9', '10', '11', '12'];
    useEffect(()=>{
        if(results){
            setResultsData(results.results)
        }
    },[results])

    useEffect(() => {
        if (data) {
            setRefresh(true)
            if ((results == null || results.results.length == 0)&& data) {
                const initialData = data.enrollmentsForCategory.map((each,index) => {
                    return {
                        "jumping_penalty": "",
                        "time": "",
                        "time_penalty": "",
                        "total_penalty": "",
                        "position": Number(""),
                        "rider_points": Number(""),
                        "horse_points": Number(""),
                        "team_points": Number(""),
                        "rider_id": Number(each.rider_id),
                        "horse_id": Number(each.horse_id),
                        "team_id": Number(each.horse_id),
                        "category_id": each.category_id,
                        "show_id": each.show_id,
                        "event_id": each.event_id,
                        "subevent_id": each.subevent_id,
                    }
                })
                setInputValues(initialData)
            }
        }
    },[data])

    const handleInputChangeEditTable = (index, fieldName, value) => {
        // Create a new array with the updated values
        const updatedResultsData = [...resultsData];
    
        // Update the value of the specified field in the selected row
        updatedResultsData[index] = {
            ...updatedResultsData[index],
            [fieldName]: value,
        };
    
        // Set the updated array back to the state
        setResultsData(updatedResultsData);
    };

    const handleInputChange = (index, fieldName, value) => {
        const newInputValues = [...inputValues];
        
        if (!newInputValues[index]) {
          // If the object for the current row does not exist, create it with all possible column names
          newInputValues[index] = {
            jumping_penalty: "",
            time: "",
            time_penalty: "",
            total_penalty: "",
            position: "",
            rider_points: "",
            horse_points: "",
            team_points: "",
            rider_id: "",
            horse_id: "",
            team_id: "",
            category_id: "",
            show_id: "",
            event_id: "",
            subevent_id: "",
            archive: "",
            updated_at: "",
            created_at: "",
            // Add more fields as needed
          };
        }
      
        // Set the values for the specified field and its corresponding hidden field
        newInputValues[index] = {
          ...newInputValues[index],
          [fieldName]: value,
        };
        console.log(newInputValues);
        setInputValues(newInputValues);
    };
    
    
    const generatePDF = () => {
        if(data){
        const doc = new jsPDF({
            orientation: 'landscape', // Set orientation to landscape
          });
          doc.text(`RESULT: ${data.enrollmentsForCategory[0].show_name.toUpperCase()}`, 20, 10);
    
        // Set the Y coordinate for the third line
        doc.setDrawColor(0); // reset draw color (black)
        doc.setFillColor(255); // set fill color to white
        doc.setLineWidth(0.5); 
        doc.setLineWidth(0.3);// set line width
        // doc.line(20, 60, 190, 60); // Horizontal line as separator
        doc.setFontSize(10);
        // Add the third line of text below the second line
        doc.text('Arena:', 20, 20);
        doc.text('Date:', 160, 20);
        doc.text(`Event: ${data.enrollmentsForCategory[0].subevent_name}`, 20, 30);
        doc.text(`Category: ${data.enrollmentsForCategory[0].category_name}`, 160, 30);

         // Add the forth line of text below the second line
          
        const tableData = {
            html: '#my-table',
            theme: 'grid', 
            startY: 40,// Specify the theme here
            styles: {
              // You can also customize other styles here
              fontSize: 8,
              fontStyle: 'normal',
              textColor: [0, 0, 0],
              cellPadding: 2,
              lineColor: [0, 0, 0],
            },
            headerStyles: {
              fillColor: [211, 211, 211], // Light grey color
            },
          };
        
          doc.autoTable(tableData);
        // Save the PDF
        doc.save(`Results_(${data.enrollmentsForCategory[0].subevent_name}).pdf`);
    }
      };
      const handleSaveResults = () => {
        // Log the input values when clicking Save Results
        console.log(inputValues);
          const payload = inputValues.map((each, index) => {
              if (each != undefined) {
                  return {
                      "jumping_penalty": each.jumping_penalty,
                      "time": each.time,
                      "time_penalty": each.time_penalty,
                      "total_penalty": each.total_penalty,
                      "position": Number(each.position),
                      "rider_points": Number(each.rider_points),
                      "horse_points": Number(each.horse_points),
                      "team_points": Number(each.team_points),
                      "rider_id": Number(document.querySelector(`input[name="hidden_rider_id_${index}"]`)?.value),
                      "horse_id": Number(document.querySelector(`input[name="hidden_horse_id_${index}"]`)?.value),
                      "team_id": Number(document.querySelector(`input[name="hidden_team_id_${index}"]`)?.value),
                      "category_id": data.enrollmentsForCategory[0].category_id,
                      "show_id": data.enrollmentsForCategory[0].show_id,
                      "event_id": data.enrollmentsForCategory[0].event_id,
                      "subevent_id": data.enrollmentsForCategory[0].subevent_id,
                  }
              }
          }) 
          const filteredPayload = payload
          .filter(item => item && Object.keys(item).length > 0) // Remove `null`
          .map((item, index) => ({
            // Reset indices or update fields if needed
            ...item,
          }));
        console.log(filteredPayload)
        postData(process.env.REACT_APP_NODE_SERVER+'/api/results/add-results',filteredPayload,"POST")
      };
      useEffect(()=>{
        if(resData && resData.status === 200){
          NotificationManager.success('Results added successfully!', 'Error', 3000);
          window.location.reload();
        }
        if(postError){
            NotificationManager.error(postError, 'Error', 3000);
        }
    },[resData,postError])
    useEffect(()=>{
        if(updateResultRes && updateResultRes.status === 200){
          NotificationManager.success('Results edited successfully!', 'Error', 3000);
          window.location.reload();
        }
        if(updateResultError){
            NotificationManager.error(postError, 'Error', 3000);
        }
    },[updateResultRes,updateResultError])
    const handleSaveChanges = () => {
        console.log(resultsData);
         updateResults(process.env.REACT_APP_NODE_SERVER+'/api/results/updateResults',resultsData,"PUT")
    };
  return (
    <div className='container pt-7'>
        <NotificationContainer />
   { data && <h2 className='mt-3'>Results For - {data.enrollmentsForCategory[0].event_name} ({data.enrollmentsForCategory[0].subevent_name})</h2>}
   { data && <h3 className='mt-3 mb-4'>Category - {data.enrollmentsForCategory[0].category_name}</h3>}
    <div className='mb-4'>{
    results && results.results.length>0 && !isEdit && <Button color="success" onClick={generatePDF}>Download Result PDF</Button>
    }
    {results && results.results.length===0 && <Button color="success" onClick={handleSaveResults}>Save Results</Button>}
    {results && results.results.length>0 && isEdit &&<Button onClick={handleSaveChanges} color="success">Save Changes</Button>}
   {results && results.results.length>0 && !isEdit &&<Button onClick={()=>setIsEdit(!isEdit)} color="success">Edit Result</Button>}
    </div>
      {results && results.results.length===0 &&<table id="my-table" className="table table-bordered">
        <thead>
            <tr>
            <th scope="col">S. NO</th>
            <th scope="col">NAME OF RIDER</th>
            <th scope="col">TEAM NAME</th>
            <th scope="col">NAME OF HORSE</th>
            {data.enrollmentsForCategory[0].event_id === 5 && <th scope="col">{data.enrollmentsForCategory[0].subevent_id===28?"TOTAL":"JUMPING PENALTY"}</th>} 
            {data.enrollmentsForCategory[0].event_id === 5 &&<th scope="col">TIME</th>}
             {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id!==28 && <th scope="col">TIME PENALTY</th>}
             {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id!==28 &&<th scope="col">TOTAL PENALTY</th>}
            <th scope="col">POSITION</th>
            <th scope="col">RIDER POINTS</th>
            <th scope="col">HORSE POINTS</th>
            <th scope="col">TEAM POINTS</th>
            </tr>
        </thead>
        <tbody>
            {data && data.enrollmentsForCategory.map((each, index) => (
            <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{each.rider_name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
                <td>{each.team_name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
                <td>{each.name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}
                <input
                    type="hidden"
                    name={`hidden_rider_id_${index}`}
                    value={each.rider_id}
                    />
                    <input
                    type="hidden"
                    name={`hidden_horse_id_${index}`}
                    value={each.horse_id}
                    />
                    <input
                    type="hidden"
                    name={`hidden_team_id_${index}`}
                    value={each.team_id}
                    />
                </td>
                
               {data.enrollmentsForCategory[0].event_id === 5 &&<td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                name={`jumping_penalty_${index}`}
                disabled={confinedArray.includes(categoryId)}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                onChange={(e) => handleInputChange(index, 'jumping_penalty', e.target.value)}
                />
            </td>}
            {data.enrollmentsForCategory[0].event_id === 5 &&<td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                name={`time_${index}`}
                disabled={confinedArray.includes(categoryId)}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                onChange={(e) => handleInputChange(index, 'time', e.target.value)}
                />
            </td>}
            {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id!==28 &&< td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                name={`time_penalty_${index}`}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                onChange={(e) => handleInputChange(index, 'time_penalty', e.target.value)}
                />
            </td>}
            {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id!==28 &&<td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                name={`total_penalty_${index}`}
                disabled={confinedArray.includes(categoryId)}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                onChange={(e) => handleInputChange(index, 'total_penalty', e.target.value)}
                />
            </td>}
            <td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                name={`position_${index}`}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                onChange={(e) => handleInputChange(index, 'position', e.target.value)}
                />
            </td>
            <td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                name={`rider_points_${index}`}
                disabled={confinedArray.includes(categoryId)}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                onChange={(e) => handleInputChange(index, 'rider_points', e.target.value)}
                />
            </td>
            <td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                name={`horse_points_${index}`}
                disabled={confinedArray.includes(categoryId)}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                onChange={(e) => handleInputChange(index, 'horse_points', e.target.value)}
                />
            </td>
            <td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                name={`team_points_${index}`}
                disabled={confinedArray.includes(categoryId)}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                onChange={(e) => handleInputChange(index, 'team_points', e.target.value)}
                />
            </td>
            </tr>
            ))}
            
        </tbody>
    </table>}
    {/* results */}
    {results && results.results.length>0 && !isEdit &&<table id="my-table" className="table table-bordered">
        <thead>
            <tr>
            <th scope="col">S. NO</th>
            <th scope="col">RIDER</th>
            <th scope="col">HORSE NAME</th>
            <th scope="col">CLUB</th>
            {data.enrollmentsForCategory[0].event_id === 5 && <th scope="col">{data.enrollmentsForCategory[0].subevent_id===28?"TOTAL":"JUMPING PENALTY"}</th>} 
            {data.enrollmentsForCategory[0].event_id === 5 && <th scope="col">TIME</th>}
             {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id !==28 &&<th scope="col">TIME PENALTY</th>}
             {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id !==28 &&<th scope="col">TOTAL PENALTY</th>}
            <th scope="col">POSITION</th>
            <th scope="col">RIDER POINTS</th>
            <th scope="col">HORSE POINTS</th>
            <th scope="col">TEAM POINTS</th>
            </tr>
        </thead>
        <tbody>
            {results && results.results.map((each, index) => (
            <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{each.rider_name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
                <td>{each.name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
                <td>{each.team_name}</td>
                {data.enrollmentsForCategory[0].event_id === 5 &&<td >{each.jumping_penalty}</td>}
                 {data.enrollmentsForCategory[0].event_id === 5 &&<td >{each.time}</td>}
                 {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id!==28 &&<td >{each.time_penalty}</td>}
                 {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id!==28 &&<td >{each.total_penalty}</td>}
                <td >{each.position}</td>
                <td >{each.rider_points}</td>
                <td >{each.horse_points}</td>
                <td >{each.team_points}</td>
            </tr>
            ))}
            
        </tbody>
    </table>}
    {/* Edit table */}
    {results && results.results.length>0 && isEdit &&<table id="my-table" className="table table-bordered">
        <thead>
            <tr>
            <th scope="col">S. NO</th>
            <th scope="col">NAME OF RIDER</th>
            <th scope="col">TEAM NAME</th>
            <th scope="col">NAME OF HORSE</th>
            {data.enrollmentsForCategory[0].event_id === 5 &&  <th scope="col">{data.enrollmentsForCategory[0].subevent_id===28?"TOTAL":"JUMPING PENALTY"}</th>} 
            {data.enrollmentsForCategory[0].event_id === 5 && <th scope="col">TIME</th>}
             {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id!==28 &&<th scope="col">TIME PENALTY</th>}
             {data.enrollmentsForCategory[0].event_id === 5&& data.enrollmentsForCategory[0].subevent_id!==28 &&<th scope="col">TOTAL PENALTY</th>}
            <th scope="col">POSITION</th>
            <th scope="col">RIDER POINTS</th>
            <th scope="col">HORSE POINTS</th>
            <th scope="col">TEAM POINTS</th>
            </tr>
        </thead>
        <tbody>
            {resultsData && resultsData.map((each, index) => (
            <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{each.rider_name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
                <td>{each.team_name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
                <td>{each.name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}
                <input
                    type="hidden"
                    name={`hidden_rider_id_${index}`}
                    value={each.rider_id}
                    />
                    <input
                    type="hidden"
                    name={`hidden_horse_id_${index}`}
                    value={each.horse_id}
                    />
                    <input
                    type="hidden"
                    name={`hidden_team_id_${index}`}
                    value={each.team_id}
                    />
                </td>
                
               {data.enrollmentsForCategory[0].event_id === 5 &&<td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                value={each.jumping_penalty}
                disabled={confinedArray.includes(categoryId)}
                onChange={(e) => handleInputChangeEditTable(index, 'jumping_penalty', e.target.value)}
                name={`jumping_penalty_${index}`}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                />
            </td>}
            {data.enrollmentsForCategory[0].event_id === 5 &&<td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                value={each.time}
                onChange={(e) => handleInputChangeEditTable(index, 'time', e.target.value)}
                name={`time_${index}`}
                disabled={confinedArray.includes(categoryId)}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                />
            </td>}
            {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id!==28 &&<td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                value={each.time_penalty}
                disabled={confinedArray.includes(categoryId)}
                onChange={(e) => handleInputChangeEditTable(index, 'time_penalty', e.target.value)}
                name={`time_penalty_${index}`}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                />
            </td>}
            {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id!==28 &&<td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                onChange={(e) => handleInputChangeEditTable(index, 'total_penalty', e.target.value)}
                value={each.total_penalty}
                disabled={confinedArray.includes(categoryId)}
                name={`total_penalty_${index}`}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                />
            </td>}
            <td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                value={each.position}
                onChange={(e) => handleInputChangeEditTable(index, 'position', e.target.value)}
                name={`position_${index}`}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                />
            </td>
            <td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                value={each.rider_points}
                disabled={confinedArray.includes(categoryId)}
                onChange={(e) => handleInputChangeEditTable(index, 'rider_points', e.target.value)}
                name={`rider_points_${index}`}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                />
            </td>
            <td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                value={each.horse_points}
                disabled={confinedArray.includes(categoryId)}
                onChange={(e) => handleInputChangeEditTable(index, 'horse_points', e.target.value)}
                name={`horse_points_${index}`}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                />
            </td>
            <td style={{ padding: '0', margin: '0' }}>
                <input
                type="text"
                onChange={(e) => handleInputChangeEditTable(index, 'team_points', e.target.value)}
                value={each.team_points}
                disabled={confinedArray.includes(categoryId)}
                name={`team_points_${index}`}
                style={{ width: '100%', padding: '16px', border: 'none', boxSizing: 'border-box' }}
                />
            </td>
            </tr>
            ))}
            
        </tbody>
    </table>}
    </div>
  )
}
