import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import moment from "moment";
import img from "../../assets/img/theme/cover-photo.jpg";
import "moment-timezone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  Spinner,
  CardTitle,
  CardSubtitle,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { usePost } from "hooks/usePost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHorseHead } from "@fortawesome/free-solid-svg-icons";

export default function ShowDetails() {
  const { showId } = useParams();
  const { data, isPending, error } = useFetch(
    process.env.REACT_APP_NODE_SERVER + "/api/show/getById/" + showId
  );
  const { data: eventCollection, error: eventError } = useFetch(
    process.env.REACT_APP_NODE_SERVER +
      "/api/event-collection/getAllEvent/" +
      showId
  );
  const {
    postData: updateEvent,
    resData: updateEventRes,
    postError: updateEventError,
    isPending: updateEventPending,
  } = usePost();
  const {
    postData: archiveEvent,
    resData: archiveEventRes,
    postError: archiveEventError,
  } = usePost();
  const { postData, resData, postError, isPending: updatePending } = usePost();
  const [showMore, setShowMore] = useState(false);
  const [showName, setShowName] = useState("");
  const [showDesc, setShowDesc] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const [venue, setVenue] = useState("");
  const [eventTime, setEventTime] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [singleEventCollection, setSingleEventCollection] = useState(null);
  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);
  const { data: categories, isPending: categoriesIsPending } = useFetch(
    process.env.REACT_APP_NODE_SERVER + "/api/category/getAll"
  );
  const { data: showDashboard, isPending: isShowDashboard } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/enrollment/getTotalCount?show_id=${showId}`
  );
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [dashboardCount, setDashboardCount] = useState({
    totalTeams: 0,
    totalRiders: 0,
    totalHorses: 0,
    totalEnrollments: 0,
  });
  useEffect(() => {
    if (singleEventCollection !== null) {
      const mappedCategories = singleEventCollection.categories.map((each) => {
        return { category: each.category_name, code: each.category_id };
      });
      setSelectedCategoryArr(mappedCategories);
    }
  }, [singleEventCollection]);
  useEffect(() => {
    if (categories && categories.status === 200) {
      const mappedCategories = categories.categories.map((each) => {
        return { category: each.category_name, code: each.category_id };
      });
      setCategoriesArr(mappedCategories);
    }
  }, [categories]);

  useEffect(() => {
    if (showDashboard && showDashboard.status === 200) {
      setDashboardCount((prev) => ({
        ...prev,
        totalTeams: showDashboard.totalTeamCount,
        totalRiders: showDashboard.totalRiderCount,
        totalHorses: showDashboard.totalHorseCount,
        totalEnrollments: showDashboard.totalEnrollmentsCount
      }));
    }
  }, [showDashboard]);

  const toggle = (data) => {
    if (!modal) {
      setEventDate(moment.utc(data.event_date).format("yyyy-MM-DD"));
      setEventTime(data.event_time);
      setSingleEventCollection(data);
    }
    setModal(!modal);
  };
  const toggleDeleteModal = (data) => {
    if (!deleteModal) {
      setSingleEventCollection(data);
    }
    setDeleteModal(!deleteModal);
    //console.log(singleEventCollection)
  };
  useEffect(() => {
    if (data) {
      //console.log(data.show.show_end_date);
      setShowName(data.show.show_name);
      setShowDesc(data.show.show_desc);
      setStartDate(moment.utc(data.show.show_start_date).format("yyyy-MM-DD"));
      setEndDate(moment.utc(data.show.show_end_date).format("yyyy-MM-DD"));
      setVenue(data.show.show_venue);
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      NotificationManager.error(error, "Error", 3000);
    }
  }, [error]);

  const handleEdit = () => {
    //console.log({showName,showDesc,startDate,endDate,venue});
    const payload = {
      show_name: showName,
      show_desc: showDesc,
      show_start_date: startDate,
      show_end_date: endDate,
      show_venue: venue,
    };
    postData(
      process.env.REACT_APP_NODE_SERVER + "/api/show/updateShow/" + showId,
      payload,
      "PUT"
    );
  };

  useEffect(() => {
    if (resData && resData.status === 200) {
      NotificationManager.success(resData.message, "Success", 3000);
      setIsEdit(false);
    }
    if (postError) {
      NotificationManager.error(postError, "Error", 3000);
    }
  }, [resData, postError]);

  const handleDelete = (id) => {
    //console.log(id);
    const payload = {};
    archiveEvent(
      process.env.REACT_APP_NODE_SERVER +
        "/api/event-collection/archive-event-collection/" +
        id,
      payload,
      "PUT"
    );
  };
  const handleUpdateEvent = (
    event_collection_id,
    show_id,
    event_id,
    subevent_id
  ) => {
    console.log(eventDate);
    const payload = {
      show_id: show_id,
      event_id: event_id,
      subevent_id: subevent_id,
      category_ids: selectedCategoryArr.map((each) => {
        return each.code;
      }),
      event_date: eventDate,
      event_time: eventTime,
      updated_at: new Date(),
    };
    updateEvent(
      process.env.REACT_APP_NODE_SERVER +
        "/api/event-collection/update-event-collection/" +
        event_collection_id,
      payload,
      "PUT"
    );
  };
  useEffect(() => {
    if (updateEventRes && updateEventRes.status === 200) {
      NotificationManager.success(
        "Events Added Successfully!",
        "Success",
        3000
      );
      setModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    if (updateEventError) {
      NotificationManager.error(updateEventError, "Error", 5500);
      return;
    }
  }, [updateEventRes, updateEventError]);
  useEffect(() => {
    if (archiveEventRes && archiveEventRes.status === 200) {
      NotificationManager.success(
        "Event Deleted Successfully!",
        "Success",
        3000
      );
      setDeleteModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    if (archiveEventError) {
      NotificationManager.error(archiveEventError, "Error", 5500);
      return;
    }
  }, [archiveEventRes, archiveEventError]);
  return (
    <div className="container pt-8">
      {/* Delete Modal */}
      {singleEventCollection && (
        <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
          <ModalHeader toggle={toggleDeleteModal}>
            Delete {singleEventCollection.event_name} - ({" "}
            {singleEventCollection.subevent_name} )
          </ModalHeader>
          <ModalBody>Are you sure you want to delete?</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() =>
                handleDelete(singleEventCollection.event_collection_id)
              }
            >
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={toggleDeleteModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {/* Edit Modal */}
      {singleEventCollection && eventDate && (
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            Update {singleEventCollection.event_name}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-birth-date"
                  >
                    Event Date
                  </label>
                  <Input
                    value={eventDate}
                    onChange={(e) => setEventDate(e.target.value)}
                    className="form-control-alternative"
                    id="input-birth-date"
                    placeholder="Birth Date"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="input-birth-date"
                  >
                    Event Time
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      type="time"
                      value={eventTime}
                      onChange={(e) => setEventTime(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              {!categoriesIsPending && (
                <div className="col-sm-12">
                  <small>Select Categories</small>
                  <div className="card flex justify-content-center">
                    <MultiSelect
                      value={selectedCategoryArr}
                      style={{
                        fontSize: "14px",
                        color: "#8898aa",
                        fontFamily: "Open Sans",
                      }}
                      options={categoriesArr}
                      optionLabel="category"
                      placeholder="Select Categories"
                      maxSelectedLabels={3}
                      className="input-group-alternative"
                      onChange={(e) => setSelectedCategoryArr(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            {!updateEventPending && (
              <Button
                color="primary"
                onClick={() =>
                  handleUpdateEvent(
                    singleEventCollection.event_collection_id,
                    singleEventCollection.show_id,
                    singleEventCollection.event_id,
                    singleEventCollection.subevent_id
                  )
                }
              >
                Update
              </Button>
            )}
            {updateEventPending && (
              <Button color="primary" disabled>
                <Spinner size="sm" /> Updating...
              </Button>
            )}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <NotificationContainer />
      <Container fluid>
        <Row className="dashboardCount">
          <Col lg="6" xl="3">
            <CardBody>
              <Row>
                <div className="col">
                  <NavLink to="/admin/view-teams">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Teams
                    </CardTitle>
                  </NavLink>
                  {
                    <span className="h2 font-weight-bold mb-0">
                      {dashboardCount.totalTeams}
                    </span>
                  }
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                    <i className="fas fa-users" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Col>
          <Col lg="6" xl="3">
            <CardBody>
              <Row>
                <div className="col">
                  <NavLink to="/admin/view-all-riders">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Riders
                    </CardTitle>
                  </NavLink>
                  {
                    <span className="h2 font-weight-bold mb-0">
                      {dashboardCount.totalRiders}
                    </span>
                  }
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                    <i className="fas fa-users" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Col>
          <Col lg="6" xl="3">
            <CardBody>
              <Row>
                <div className="col">
                  <NavLink to={"/admin/view-all-horses"}>
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Horses
                    </CardTitle>
                  </NavLink>
                  {
                    <span className="h2 font-weight-bold mb-0">
                      {dashboardCount.totalHorses}
                    </span>
                  }
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                    <FontAwesomeIcon icon={faHorseHead} />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Col>
          <Col lg="6" xl="3">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Entries
                  </CardTitle>
                  {
                    <span className="h2 font-weight-bold mb-0">
                      {dashboardCount.totalEnrollments}
                    </span>
                  }
                </div>
                <Col className="col-auto">
                  <div
                    style={{ background: `var(--cyan)` }}
                    className="icon icon-shape text-white rounded-circle shadow"
                  >
                    <i className="fas fa-users" />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>
        {isPending && (
          <div className="text-center">
            <Spinner color="primary" style={{ height: "3rem", width: "3rem" }}>
              {" "}
              Loading...
            </Spinner>
          </div>
        )}
        {!isPending && data && (
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <CardBody className="pt-0 pt-md-4">
                  <div className="text-center">
                    <h3>
                      {data.show.show_name}
                      <span className="font-weight-light"></span>
                    </h3>
                    <div className="h5 font-weight-400">
                      <i className="ni location_pin mr-2" />
                      {new Date(data.show.show_start_date).toLocaleDateString(
                        "en-US",
                        { day: "numeric", month: "short", year: "numeric" }
                      )}{" "}
                      to{" "}
                      {new Date(data.show.show_end_date).toLocaleDateString(
                        "en-US",
                        { day: "numeric", month: "short", year: "numeric" }
                      )}
                    </div>
                    {!showMore && (
                      <div className="h5 font-weight-400">
                        <i className="ni location_pin mr-2" />
                        {data.show.show_desc.split(" ").slice(0, 20).join(" ")}
                        ...
                      </div>
                    )}
                    {showMore && (
                      <div className="h5 font-weight-400">
                        <i className="ni location_pin mr-2" />
                        {data.show.show_desc}
                      </div>
                    )}
                    <hr className="my-4" />
                    {!showMore && (
                      <a href="#pablo" onClick={() => setShowMore(true)}>
                        Show more
                      </a>
                    )}
                    {showMore && (
                      <a href="#pablo" onClick={() => setShowMore(false)}>
                        Show less
                      </a>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Show Details</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {!isEdit && (
                        <Button
                          color="primary"
                          href="#pablo"
                          onClick={() => setIsEdit(true)}
                          size="sm"
                        >
                          Edit
                        </Button>
                      )}
                      {isEdit && (
                        <Button
                          color="primary"
                          href="#pablo"
                          onClick={handleEdit}
                          size="sm"
                        >
                          Save
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {updatePending && (
                    <div className="text-center">
                      <Spinner
                        color="primary"
                        style={{ height: "3rem", width: "3rem" }}
                      >
                        {" "}
                        Loading...
                      </Spinner>
                    </div>
                  )}
                  {!updatePending && (
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Show information
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Show Title
                              </label>
                              <Input
                                className="form-control-alternative"
                                value={showName}
                                id="input-first-name"
                                placeholder="First name"
                                type="text"
                                readOnly={!isEdit}
                                onChange={(e) => setShowName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="venue"
                              >
                                Venue
                              </label>
                              <Input
                                className="form-control-alternative"
                                value={venue}
                                readOnly={!isEdit}
                                onChange={(e) => setVenue(e.target.value)}
                                id="venue"
                                placeholder="Venue"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Start Date
                              </label>
                              <Input
                                className="form-control-alternative"
                                value={startDate}
                                id="input-last-name"
                                placeholder="Last name"
                                type="date"
                                readOnly={!isEdit}
                                onChange={(e) => setStartDate(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                End Date
                              </label>
                              <Input
                                className="form-control-alternative"
                                value={endDate}
                                id="input-last-name"
                                placeholder="Last name"
                                type="date"
                                readOnly={!isEdit}
                                onChange={(e) => setEndDate(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Description
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="jesse@example.com"
                                type="textarea"
                                value={showDesc}
                                readOnly={!isEdit}
                                onChange={(e) => setShowDesc(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <h2 className="mt-5">Event(s)</h2>
        {showName && (
          <Link to={`/admin/create-event-collection/${showId}/${showName}`}>
            <Button className="my-3" color="primary">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ fontWeight: "700" }}
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
              </svg>{" "}
              Create More Events
            </Button>
          </Link>
        )}
        <div className="row">
          {eventError && (
            <p className="text-danger">Sorry! no events are available!</p>
          )}
          {eventCollection &&
            eventCollection.eventCollection.map((each) => (
              <div className="col-sm-4" key={each.event_collection_id}>
                <Card className="mt-2">
                  <img
                    alt="Sample"
                    src={
                      each.photo_url
                        ? process.env.REACT_APP_NODE_SERVER +
                          "/uploads/" +
                          each.photo_url
                        : img
                    }
                    style={{
                      position: "relative",
                      height: 180,
                      objectFit: "cover",
                    }}
                  />
                  <svg
                    onClick={() => toggleDeleteModal(each)}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "10",
                      top: "10",
                      background: "rgba(0,0,0,0.55",
                      borderRadius: "18px",
                      padding: "4px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="white"
                    className="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                  </svg>
                  <svg
                    onClick={() => toggle(each)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "10",
                      top: "45",
                      background: "rgba(0,0,0,0.55",
                      borderRadius: "18px",
                      padding: "4px",
                    }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M15.7279 9.57627L14.3137 8.16206L5 17.4758V18.89H6.41421L15.7279 9.57627ZM17.1421 8.16206L18.5563 6.74785L17.1421 5.33363L15.7279 6.74785L17.1421 8.16206ZM7.24264 20.89H3V16.6473L16.435 3.21231C16.8256 2.82179 17.4587 2.82179 17.8492 3.21231L20.6777 6.04074C21.0682 6.43126 21.0682 7.06443 20.6777 7.45495L7.24264 20.89Z"
                      fill="white"
                    ></path>
                  </svg>
                  <CardBody>
                    <CardTitle tag="h3">{each.event_name}</CardTitle>
                    <CardSubtitle className="mb-2" tag="h5">
                      ( {each.subevent_name} )
                    </CardSubtitle>
                    <CardText>
                      <div>
                        <small className="mr-2">
                          <i className="ri-calendar-event-line"></i>{" "}
                          {new Date(each.event_date).toLocaleDateString(
                            "en-US",
                            { day: "numeric", month: "short", year: "numeric" }
                          )}
                        </small>{" "}
                        <small>
                          <i className="ri-time-line"></i>{" "}
                          {new Date(
                            `1970-01-01T${each.event_time}Z`
                          ).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                            timeZone: "UTC",
                          })}
                        </small>
                      </div>
                      {each.categories.map((each) => (
                        <small key={each.category_id}>
                          • {each.category_name} &nbsp;
                        </small>
                      ))}
                    </CardText>
                    <Link className="mr-4"
                      to={`/admin/add-enrollments/${each.show_id}/${each.event_id}/${each.subevent_id}/${each.event_collection_id}`}
                    >
                      <Button color="primary">Add Entries</Button>
                    </Link>
                    <Link
                      to={`/admin/enrollment-details/${each.show_id}/${each.event_id}/${each.subevent_id}/${each.event_collection_id}`}
                    >
                      <Button color="primary">View Entries</Button>
                    </Link>
                  </CardBody>
                </Card>
              </div>
            ))}
        </div>
        {showName && (
          <Link to={`/admin/create-event-collection/${showId}/${showName}`}>
            <Button className="mt-5" color="primary">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ fontWeight: "700" }}
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
              </svg>{" "}
              Create More Events
            </Button>
          </Link>
        )}
      </Container>
    </div>
  );
}
