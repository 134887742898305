import { useFetch } from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Row,
  Spinner,
  Button
} from "reactstrap";
import { usePost } from 'hooks/usePost';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function ShowOfficeEnrollmentDetails() {
  const { showId, eventId, subEventId, eventCollectionId } = useParams();
  const { isPending, error, data } = useFetch(process.env.REACT_APP_NODE_SERVER + '/api/event-collection/getEventCollectionById/' + eventCollectionId);
  const { data: show } = useFetch(process.env.REACT_APP_NODE_SERVER + '/api/show/getById/' + showId);
  const { data: event } = useFetch(process.env.REACT_APP_NODE_SERVER + '/api/event/getEventById/' + eventId);
  const { data: subEvent } = useFetch(process.env.REACT_APP_NODE_SERVER + '/api/subevent/getSubeventById/' + subEventId);
  const [categoryIds, setCategoryIds] = useState(null);
  const [enrollments, setEnrollments] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const { postData, resData, postError } = usePost();
  const [order, setOrder] = useState([])

  useEffect(() => {
    console.log('Component re-rendered!');
  }, [enrollments]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return; // No destination, returning...
    }
  
    const sourceCategoryId = result.source.droppableId;
    const destinationCategoryId = result.destination.droppableId;
  
    if (!enrollments) {
      return; // Enrollments is null or undefined, returning...
    }
  
    const updatedEnrollments = { ...enrollments };
    const sourceEnrollments = updatedEnrollments[sourceCategoryId];
    const destinationEnrollments = updatedEnrollments[destinationCategoryId];
  
    if (!sourceEnrollments || !destinationEnrollments) {
      return; // Source or destination enrollments are undefined, returning...
    }
  
    const movedEnrollment = sourceEnrollments[result.source.index];
  
    // Remove from source
    sourceEnrollments.splice(result.source.index, 1);
    // Insert into destination
    destinationEnrollments.splice(result.destination.index, 0, movedEnrollment);
  
    setEnrollments(updatedEnrollments);
  
    // Extract the enrollment IDs in the new order
    const newOrderEnrollmentIds = destinationEnrollments.map(enrollment => enrollment.enrollment_id);
  
    // Make an API call to update the order on the server
  postData(`${process.env.REACT_APP_NODE_SERVER}/api/enrollment/update-rider-order/${destinationCategoryId}`,{ order: destinationEnrollments },"PUT")
  };
   
  useEffect(() => {
    if (data && data.status === 200) {
      setCategoryIds(JSON.parse(data.eventCollection.category_ids));
    }
    if (error) {
      NotificationManager.error(error, 'Error', 3000);
    }
  }, [data, error]);

  useEffect(() => {
    if (resData && resData.status === 200) {
      NotificationManager.success('Rider order changed successfully!', 'Success', 3000);
    }
    if (postError) {
      NotificationManager.error(postError, 'Error', 3000);
    }
  }, [resData, postError]);

  useEffect(() => {
    if (categoryIds !== null) {
      fetchEnrollments();
    }
  }, [categoryIds]);

  const fetchEnrollments = async () => {
    try {
      setIsloading(true);
      const response = await fetch(`${process.env.REACT_APP_NODE_SERVER}/api/enrollment/getEnrollmentsForEvents/${eventId}/${subEventId}/${showId}/${eventCollectionId}/?category_ids=${categoryIds.join(',')}`);
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }
      const data = await response.json();
      console.log('Fetched Data:', data);

      if (data.status !== 200) {
        throw new Error("Cannot get data!");
      }

      const groupedEnrollments = data.groupedEnrollments;
      console.log('Grouped Enrollments:', groupedEnrollments);

      // Ensure that groupedEnrollments is an object with keys
      if (typeof groupedEnrollments === 'object' && Object.keys(groupedEnrollments).length > 0) {
        setEnrollments(groupedEnrollments);
        setIsloading(false);
      } else {
        throw new Error("Invalid or empty groupedEnrollments");
      }
    } catch (err) {
      console.error('Error in fetchEnrollments:', err);
      NotificationManager.error(err.message, 'Error', 3000);
      setIsloading(false); // Ensure loading is set to false in case of an error
    }
  };

  if (isPending && isLoading) {
    return (
      <div className='container pt-8 text-center'>
        <NotificationContainer />
        <Spinner
          color="primary"
          style={{ height: "3rem", width: "3rem" }}
        ></Spinner>
      </div>
    )
  } else {
    return (
      <div className='container pt-8'>
        <NotificationContainer />
        {show && <h2>Show Name - {show.show.show_name}</h2>}
        {event && subEvent && <h3 className='pb-3'>Enrollments for {event.event.event_name} - ( {subEvent.subevent.subevent_name} )</h3>}
        {enrollments && Object.keys(enrollments).length === 0 && <p className='text-warning'>No Enrollments</p>}
        <DragDropContext onDragEnd={onDragEnd}>
          {enrollments && Object.keys(enrollments).map((categoryId, index) => (
            <Row key={categoryId} className='mb-5'>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className='d-flex'>
                      <h3 className="mb-0">{enrollments[categoryId][0].category_name}</h3>
                      <div className="ml-auto">
                        <Link className="mr-2" to={`/showoffice/print-start-order/${eventId}/${subEventId}/${showId}/${eventCollectionId}/${categoryId}`}><Button
                          color="success"
                          size="sm"
                        >
                          Print Start Order
                        </Button></Link>
                        <Link className="ml-auto mr-2" to={`/showoffice/print-scoringsheets/${eventId}/${subEventId}/${showId}/${eventCollectionId}/${categoryId}`}><Button
                          color="success"
                          size="sm"
                        >
                          Print Scoring sheets
                        </Button></Link>
                        <Link className="ml-auto" to={`/showoffice/results/${eventId}/${subEventId}/${showId}/${eventCollectionId}/${categoryId}`}><Button
                          color="success"
                          size="sm"
                        >
                          Results
                        </Button></Link>
                      </div>
                    </div>
                  </CardHeader>
                  <Droppable droppableId={categoryId} type="LIFTER" key={categoryId}>
                    {(provided,snapshot) => (
                      <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`droppable-area${snapshot.isDraggingOver ? ' dragging-over' : ''}`}
                    >
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th />
                            <th>S. NO</th>
                            <th scope="col">Rider Name</th>
                            <th scope="col">Horse Name</th>
                            <th scope="col">Team Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {enrollments[categoryId].map((enrollment, index) => (
                            <Draggable key={enrollment.enrollment_id} draggableId={enrollment.enrollment_id.toString()} index={index}>
                              {(provided) => (
                                <tr {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                  <th className="vertical-line">
                                  <i className="ri-drag-move-2-fill"></i>
                                  </th>
                                  <th className="vertical-line">{index + 1}</th>
                                  <th className="vertical-line" scope="row">{enrollment.rider_name} {enrollment.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</th>
                                  <td className="vertical-line">{enrollment.name} {enrollment.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
                                  <td className="vertical-line">{enrollment.team_name}</td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                        </Table>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      {/* Pagination and other footer content */}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          ))}
        </DragDropContext>
      </div>
    );
  }
}
