import React from 'react';
import { useFetch } from 'hooks/useFetch';
import { useParams } from 'react-router-dom';
import BallAndBucket from './components/scoring-sheets/BallAndBucket';
import ShowJumpingChallange from './components/scoring-sheets/ShowJumpingChallange';
import ShowJumpingFaultAndOut from './components/scoring-sheets/ShowJumpingFaultAndOut';
import ShowJumpingNormal from './components/scoring-sheets/ShowJumpingNormal';
import ShowJumpingRelay from './components/scoring-sheets/ShowJumpingRelay';
import ShowJumpingTakeYourLine from './components/scoring-sheets/ShowJumpingTakeYourLine';
import ShowJumpingTopScore from './components/scoring-sheets/ShowJumpingTopScore';
import Equitation from './components/scoring-sheets/Equitation';
import LeadingEquitation from './components/scoring-sheets/LeadingEquitation';
import TrotRace from './components/scoring-sheets/TrotRace';
import PoleBending from './components/scoring-sheets/PoleBending';
import HatRace from './components/scoring-sheets/HatRace';
import Dressage from './components/scoring-sheets/Dressage';

export default function PrintScoringSheets() {
  const { eventId, subeventId, showId, eventCollectionId,categoryId } = useParams();
  const { data } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/enrollment/getEnrollmentsForEventsByCategory/${eventId}/${subeventId}/${showId}/${eventCollectionId}/${categoryId}`
  );

  return (
    <div className="container pt-8">
       { data && <h2 className='mt-3'>Score Sheet For - {data.enrollmentsForCategory[0].event_name} ({data.enrollmentsForCategory[0].subevent_name})</h2>}
      { data && <h3 className='mt-3 mb-4'>Category - {data.enrollmentsForCategory[0].category_name}</h3>}
      { subeventId === "20" && <BallAndBucket data={data} />}
      {subeventId === "31" && <ShowJumpingChallange data={data}/>}
     { subeventId === "29" && <ShowJumpingFaultAndOut data={data}/>}
     { 
    (subeventId === "21" || subeventId === "22" || subeventId === "23" || subeventId === "24" || subeventId === "25" || subeventId === "26" || subeventId === "27") && 
    <ShowJumpingNormal data={data} />
    }
      {subeventId === "11" && <Dressage data={data}/>}
     { subeventId === "30" && <ShowJumpingRelay data={data}/>}
      {subeventId === "31" && <ShowJumpingTakeYourLine data={data}/>}
      {subeventId === "28" && <ShowJumpingTopScore data={data}/>}
      {subeventId === "39" && <Equitation data={data}/>}
      {subeventId === "40" && <LeadingEquitation data={data}/>}
      {subeventId === "16" && <TrotRace data={data}/>}
      {subeventId === "15" && <PoleBending data={data}/>}
      {subeventId === "14" && <HatRace data={data}/>}
    </div>
  );
}
