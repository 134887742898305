import { useFetch } from 'hooks/useFetch';

// reactstrap components
import {
    Card,
    CardHeader,
    Table,
    Row,
    Spinner,
    FormGroup,
    Label,
    InputGroup,
    Input,
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
import { useState } from 'react';

export default function TopTeam() {
  const [payload, setPayload] = useState('');
  const { data: team, isPending: teamIsPending } = useFetch(process.env.REACT_APP_NODE_SERVER + '/api/results/getTotalTeamPoints'+payload);
    const { data:shows, isPending:showPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/show/getAllShowsAdmin?sortBy=-1');
    const [show, setShow] = useState({ id: "", name: "" });
  
    const handleShowChange = (e) => {
      const selectedValue = e.target.value;
      const [showId, showName] = selectedValue.split(':');
      setShow({ id: showId, name: showName });
      setPayload('?show_id='+showId);
    };
  return (
    <div className="container pt-8">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <FormGroup className="mb-3 inputWithLabel">
            <Label className="input_label">Select Show :</Label>
            <InputGroup className="input-group-alternative">
              <Input
                placeholder="Shows"
                type="select"
                value={show.id+':'+show.name}
                onChange={handleShowChange}
              >
                <option value="">Select Show</option>
                {shows &&
                  shows.shows.map((show,key) => (
                    <option
                      value={`${show.show_id}:${show.show_name}`}
                      key={show.show_id}
                    >
                      {show.show_name}
                    </option>
                  ))}
              </Input>
            </InputGroup>
          </FormGroup>
        </div>
      </div>
      {team && team.totalTeamPointsWithNames.length === 0 && (
        <p className="text-danger">The results are not published yet!</p>
      )}
      {team && team.totalTeamPointsWithNames.length > 0 && (
        <Row>
          <div className="col">
            {
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Top Team</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th />
                      <th scope="col">Team Name</th>
                      <th scope="col">Team Point</th>
                    </tr>
                  </thead>
                  <tbody>
                    {team &&
                      team.totalTeamPointsWithNames.map((team, index) => (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          <th scope="row">{team.team_name}</th>
                          <th>{team.total_team_points}</th>
                        </tr>
                      ))}
                    {teamIsPending && (
                      <tr className="d-flex justify-content-center align-items-center p-4">
                        <td>
                          <Spinner
                            color="primary"
                            style={{ height: "3rem", width: "3rem" }}
                          >
                            {" "}
                            Loading...
                          </Spinner>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            }
          </div>
        </Row>
      )}
    </div>
  );
}
