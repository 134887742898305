import { useFetch } from 'hooks/useFetch'
import React, { useState } from 'react'
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import {
   Button,
   FormGroup,
   Input,
   InputGroup,
   Label
  } from "reactstrap";
export default function ViewAllHorsesAdmin() {
  const [payload, setPayload] = useState('');
  const { data } = useFetch(process.env.REACT_APP_NODE_SERVER + '/api/horse/getAll' + payload)
  const { data:shows, isPending:showPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/show/getAllShowsAdmin?sortBy=-1');
      const [show, setShow] = useState({ id: "", name: "" });
    const generatePDF = () => {
        if(data){
        const doc = new jsPDF({
            orientation: 'landscape', // Set orientation to landscape
          });
          doc.text(`HORSE LIST: JAPALOUPPE ANNUAL EQUESTRIAN SHOW `, 20, 10);
    
        // Set the Y coordinate for the third line
        doc.setDrawColor(0); // reset draw color (black)
        doc.setFillColor(255); // set fill color to white
        doc.setLineWidth(0.5); 
        doc.setLineWidth(0.3);// set line width
        // doc.line(20, 60, 190, 60); // Horizontal line as separator
        doc.setFontSize(10);
          
        const tableData = {
            html: '#my-table',
            theme: 'grid', 
            startY: 40,// Specify the theme here
            styles: {
              // You can also customize other styles here
              fontSize: 8,
              fontStyle: 'normal',
              textColor: [0, 0, 0],
              cellPadding: 2,
              lineColor: [0, 0, 0],
            },
            headerStyles: {
              fillColor: [211, 211, 211], // Light grey color
            },
          };
        
          doc.autoTable(tableData);
        // Save the PDF
        doc.save(`Horse_List.pdf`);
    }
  };
  const handleShowChange = (e) => {
    const selectedValue = e.target.value;
    const [showId, showName] = selectedValue.split(':');
    setShow({ id: showId, name: showName });
    setPayload('?show_id='+showId);
  };
  return (
    <div className="pt-8 container">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <FormGroup className="mb-3 inputWithLabel">
            <Label className="input_label"><i
                    className="ri-filter-3-line"
                    title="Filters"
                    style={{ fontSize: "22px", cursor: "pointer" }}
                  ></i></Label>
            <InputGroup className="input-group-alternative">
              <Input
                placeholder="Shows"
                type="select"
                value={show.id + ":" + show.name}
                onChange={handleShowChange}
              >
                <option value="">Select Show</option>
                {shows &&
                  shows.shows.map((show, key) => (
                    <option
                      value={`${show.show_id}:${show.show_name}`}
                      key={show.show_id}
                    >
                      {show.show_name}
                    </option>
                  ))}
              </Input>
            </InputGroup>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-sm-12">
          <div className="mb-4" style={{float:'right'}}>
            <Button color="success" onClick={generatePDF}>
              Download PDF
            </Button>
          </div>
        </div>
      </div>
      {data && Object.keys(data.horses).length == 0 && 'No horses available for this show'}
      {data && Object.keys(data.horses).length > 0 &&
        <table id="my-table" className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">S. NO</th>
              <th scope="col">HORSE NAME</th>
              <th scope="col">GENDER</th>
              <th scope="col">BIRTH YEAR</th>
              <th scope="col">COLOR</th>
              <th scope="col">TEAM</th>
            </tr>
          </thead>
          <tbody>
            {data.horses.map((each, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{each.name}</td>
                <td>{each.gender}</td>
                <td>{each.birth_year}</td>
                <td>{each.color}</td>
                <td>{each.team_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      }
    </div>
  );
}
