import React, { useEffect, useState } from 'react';
import "./Notifications.css"
import { useFetch } from 'hooks/useFetch';
import {
   Button,
   Spinner
  } from "reactstrap";
import { usePost } from 'hooks/usePost';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
function Notifications() {
    const {data,isPending} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/migrate-horse/getAll');
    const {postData,resData,postError} = usePost();
    const {postData:updateApprovalStatus,resData:approvalRes,postError:approvalErr} = usePost();
    const {postData:sendEmail,resData:emailRes,postError:emailErr} = usePost();
    const [isLoading,setIsloading] = useState(false);
    const [migrationId,setMigrationId] = useState('');
    const [singleObj,setSingleObj] = useState(null);
    const [notificationsToShow, setNotificationsToShow] = useState(5);
    const [totalNotifications,setTotalNotifications] = useState(null);
    const [isDisabled,setIsDisabled] = useState(false);

    useEffect(()=>{
        if(data){
            setTotalNotifications(data.migrations.length);
        }
    },[data])

    const loadMoreNotifications = () => {
        if(notificationsToShow>=totalNotifications){
            setIsDisabled(true);
            return;
        }
        setNotificationsToShow(prevCount => prevCount + 3);
    };

    function timeSince(timestamp) {
        const seconds = Math.floor((new Date() - new Date(timestamp)) / 1000);
        let interval = Math.floor(seconds / 31536000);
    
        if (interval >= 1) {
            return interval + " year" + (interval === 1 ? "" : "s") + " ago";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            return interval + " month" + (interval === 1 ? "" : "s") + " ago";
        }
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
            return interval + " day" + (interval === 1 ? "" : "s") + " ago";
        }
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
            return interval + " hour" + (interval === 1 ? "" : "s") + " ago";
        }
        interval = Math.floor(seconds / 60);
        if (interval >= 1) {
            return interval + " minute" + (interval === 1 ? "" : "s") + " ago";
        }
        return Math.floor(seconds) + " second" + (seconds === 1 ? "" : "s") + " ago";
    }    
    const handleSendEmail = (obj) =>{
        //console.log(isHorseSelected);
        const payload = {
          to:obj.email,
          subject: `Enrollment Done Successfully! - ${obj.show_name} `,
          text: `Congratulations ${obj.rider_name},
          
          You have been successfully enrolled for ${obj.event_name} - ( ${obj.subevent_name} )
          Horse Name: ${obj.name}
          `
        }
        sendEmail(process.env.REACT_APP_NODE_SERVER+'/api/team/send-email',payload,"POST");
      }
    const handleMigration = async(horseId,teamId,migration_id,migration_type,obj) => {
        setMigrationId(migration_id);
        setSingleObj(obj);
        if(migration_type===1){
            const payload= {
                "rider_id": obj.rider_id,
                "category_id" : obj.category_id,
                "horse_id": obj.horse_id,
                "team_id": obj.current_team_id,
                "show_id": obj.show_id,
                "eventcollection_id":obj.eventcollection_id,
                "event_id": obj.event_id,
                "subevent_id": obj.subevent_id,
                "archive": 0,
                "hc":false,
                "post_entry":obj.post_entry,
                "created_at": new Date(),
                "updated_at": new Date()
              }
            console.log(payload)
                const apiUrl = `${process.env.REACT_APP_NODE_SERVER}/api/enrollment/add-enrollment`;
                const requestOptions = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                  };
                  try {
                    setIsloading(true);
                    const response = await fetch(apiUrl, requestOptions);
                    if (!response.ok) {
                      throw new Error('Network response was not ok');
                    }
                    const responseData = await response.json();
                   console.log(responseData)
                   if(responseData.status===200){
                    handleSendEmail(obj);
                   }
                  } catch (error) {
                    console.error('Error:', error);
                    NotificationManager.error(error.message,'Error', 3000);
                    isLoading(false);
                  }
        }
        if(migration_type===2){
            const payload= {
                "rider_id": obj.rider_id,
                "category_id" : obj.category_id,
                "horse_id": obj.horse_id,
                "team_id": obj.current_team_id,
                "show_id": obj.show_id,
                "eventcollection_id":obj.eventcollection_id,
                "event_id": obj.event_id,
                "subevent_id": obj.subevent_id,
                "archive": 0,
                "hc":false,
                "post_entry":obj.post_entry,
                "created_at": new Date(),
                "updated_at": new Date()
              }
            console.log(payload)
                const apiUrl = `${process.env.REACT_APP_NODE_SERVER}/api/enrollment/add-enrollment`;
                const requestOptions = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                  };
                  try {
                    setIsloading(true);
                    const response = await fetch(apiUrl, requestOptions);
                    if (!response.ok) {
                      throw new Error('Network response was not ok');
                    }
                    const responseData = await response.json();
                    if(responseData.status===200){
                        postData(process.env.REACT_APP_NODE_SERVER+"/api/horse/migrate-horse/"+horseId,{team_id:teamId,club_name:obj.requesting_team_name},"PUT")
                       }
                  } catch (error) {
                    console.error('Error:', error);
                    NotificationManager.error(error.message,'Error', 3000);
                    isLoading(false);
                  }
        }
      
    }
    useEffect(()=>{
        if(resData && resData.status===200){
            handleSendEmail(singleObj);
        }
        if(postError){
            NotificationManager.error(postError,'Error', 3000);
        }
    },[resData,postError])

    const handleApprovalStatus = () =>{
        updateApprovalStatus(process.env.REACT_APP_NODE_SERVER+'/api/migrate-horse/update-approval-status/'+migrationId,{},"PUT");
    }

    useEffect(()=>{
        if(approvalRes && approvalRes.status===200){
            NotificationManager.success("Rider Enrolled Successfully!",'Success', 3000);
        }
        if(approvalErr){
            NotificationManager.error(approvalErr,'Error', 3000);
        }
    },[approvalRes,approvalErr])

    useEffect(() => {
        if(emailRes && emailRes.status===200){
          NotificationManager.success('Email sent to rider successfuly!','Success', 1000);
          handleApprovalStatus();
          setIsloading(false);
          setTimeout(()=>{
            window.location.reload();
          },1200)
        }
        if(emailErr){
          NotificationManager.error(emailErr,'Error', 3000);
        }
       }, [emailRes,emailErr])
    return (
        <div className='container pt-7'>
            <NotificationContainer/>
            {isPending && 
            <div className="text-center"> 
                <Spinner color="primary"style={{ height: '3rem', width: '3rem'}}></Spinner>
            </div>}
            {data && <section className="section-50">
                <div className="container">
                    <h3 className="m-b-50 heading-line">Notifications <i className="fa fa-bell text-muted"></i></h3>
                    {data.migrations.length===0 && <h4 className='text-warning'>No Notifications to show!</h4>}
                    {data.migrations.slice(0, notificationsToShow).map((each)=>(<div key={each.migration_id} className="notification-ui_dd-content">
                        <div className={`notification-list notification-list--${each.is_approved ===1 ? "read":"unread"}`}>
                            <div className="notification-list_content">
                                <div className="notification-list_img">
                                    <img src={`${process.env.REACT_APP_NODE_SERVER}/uploads/${each.requesting_team_logo}`} alt="user" />
                                </div>
                                <div className="notification-list_detail">
                                    <p><b>Request for horse migration</b> 
                                    </p>
                                    <p className="text-muted"><span style={{fontWeight:"bold"}}>{each.rider_name}</span> from team {each.requesting_team_name} is requesting {each.name}(horse) from team {each.current_team_name};</p>
                                    <p className="text-muted">For {each.show_name}, discipline {each.event_name}, sub discipline {each.subevent_name} in {each.category_name} categroy.</p>
                                    <p className="text-muted">Migration Type: {each.migration_type === 1 ? "Single Event Lease" : "Permanant Transfer"}</p>
                                    {!isLoading && <Button onClick={()=>handleMigration(each.horse_id,each.requesting_team_id,each.migration_id,each.migration_type,each)} size="sm" className="my-2" color="success" disabled={each.is_approved === 1 ? true : false }>{each.is_approved === 1 ? "Approved" : "Approve" }</Button>}
                                    {isLoading && migrationId === each.migration_id && <Button color='success' size="sm" disabled>Loading...</Button>}
                                    <p className="text-muted"><small>{timeSince(each.created_at)}</small></p>
                                </div>
                            </div>
                            <div className="notification-list_feature-img">
                                <img src={`${process.env.REACT_APP_NODE_SERVER}/${each.horse_photo}`} alt="Feature" />
                            </div>
                        </div>
                    </div>))}

                    <div className="text-center">
                    <Button disabled={isDisabled} onClick={loadMoreNotifications} color="primary">Load more notifications</Button>
                    </div>

                </div>
            </section>}
        </div>
    );
}

export default Notifications;
