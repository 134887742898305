import React from 'react'
import { Button } from 'reactstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useFetch } from 'hooks/useFetch';
import { useParams } from 'react-router-dom';
export default function PrintStartOrder() {
    const { eventId, subeventId, showId, eventCollectionId,categoryId } = useParams();
    const { data } = useFetch(
      `${process.env.REACT_APP_NODE_SERVER}/api/enrollment/getEnrollmentsForEventsByCategory/${eventId}/${subeventId}/${showId}/${eventCollectionId}/${categoryId}`
    );
    const generatePDF = () => {
        if(data){
        const doc = new jsPDF({
            orientation: 'landscape', // Set orientation to landscape
          });
          doc.text(`START ORDER: ${data.enrollmentsForCategory[0].show_name.toUpperCase()}`, 20, 10);
    
        // Set the Y coordinate for the third line
        doc.setDrawColor(0); // reset draw color (black)
        doc.setFillColor(255); // set fill color to white
        doc.setLineWidth(0.5); 
        doc.setLineWidth(0.3);// set line width
        // doc.line(20, 60, 190, 60); // Horizontal line as separator
        doc.setFontSize(10);
        // Add the third line of text below the second line
        doc.text('Arena:', 20, 20);
        doc.text('Date:', 160, 20);
        doc.text(`Event: ${data.enrollmentsForCategory[0].subevent_name}`, 20, 30);
        doc.text(`Category: ${data.enrollmentsForCategory[0].category_name}`, 160, 30);

         // Add the forth line of text below the second line
          
        const tableData = {
            html: '#my-table',
            theme: 'grid', 
            startY: 40,// Specify the theme here
            styles: {
              // You can also customize other styles here
              fontSize: 8,
              fontStyle: 'normal',
              textColor: [0, 0, 0],
              cellPadding: 2,
              lineColor: [0, 0, 0],
            },
            headerStyles: {
              fillColor: [211, 211, 211], // Light grey color
            },
          };
        
          doc.autoTable(tableData);
          doc.text('Name of Judge 1:', 20, doc.autoTable.previous.finalY + 10);
          doc.text('Signature of Judge 1:', 80, doc.autoTable.previous.finalY + 10);
          doc.text('Name of Judge 2:', 20, doc.autoTable.previous.finalY + 20);
          doc.text('Signature of Judge 2:', 80, doc.autoTable.previous.finalY + 20);
          doc.line(20, doc.autoTable.previous.finalY + 30, 60, doc.autoTable.previous.finalY + 30);
          doc.text('President Ground Jury', 20, doc.autoTable.previous.finalY + 40);
        // Save the PDF
        doc.save(`start_order_(${data.enrollmentsForCategory[0].subevent_name}).pdf`);
    }
      };
  return (
    <div className='container pt-7'>
   { data && <h2 className='mt-3'>Start Order For - {data.enrollmentsForCategory[0].event_name} ({data.enrollmentsForCategory[0].subevent_name})</h2>}
   { data && <h3 className='mt-3 mb-4'>Category - {data.enrollmentsForCategory[0].category_name}</h3>}
    <div className='mb-4'><Button color="success" onClick={generatePDF}>Download PDF</Button></div>
      <table id="my-table" className="table table-bordered">
  <thead>
    <tr>
      <th scope="col">S. NO</th>
      <th scope="col">NAME OF RIDER</th>
      <th scope="col">HORSE NAME</th>  
      <th scope="col">TEAM NAME</th>   
    </tr>
  </thead>
  <tbody>
    {data && data.enrollmentsForCategory.map((each, index) => (
      <tr key={index}>
        <th scope="row">{index + 1}</th>
        <td>{each.rider_name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
        <td>{each.name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
        <td>{each.team_name}</td>
      </tr>
    ))}
    
  </tbody>
</table>
    </div>
  )
}
