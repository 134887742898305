// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, Spinner } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHorseHead } from '@fortawesome/free-solid-svg-icons';
import { faHatCowboy } from '@fortawesome/free-solid-svg-icons';
import { useFetch } from "hooks/useFetch";
import { Link } from "react-router-dom";

const Header = () => {
  const {data,isPending}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getAll');
  const {data:shows,isPending:showPending}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/show/getAll');
  const {data:riders,isPending:riderPending}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/rider/getAll');
  const {data:horses,isPending:horsePending}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/horse/getAll');
  return (
    <>
    {(isPending || showPending || riderPending || horsePending) && <div className="text-center bg-gradient-info  p-9 ">
        <Spinner
          color="primary"
          style={{ height: "3rem", width: "3rem" }}
        ></Spinner>
      </div>}
      {(!isPending || !showPending || !riderPending || !horsePending) && <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
                <Col lg="6" xl="3">
                  {
                    <Link to="/admin/view-shows">
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Shows
                              </CardTitle>
                              {shows && (
                                <span className="h2 font-weight-bold mb-0">
                                  {shows.shows.length}
                                </span>
                              )}
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                <FontAwesomeIcon icon={faHatCowboy} />
                              </div>
                            </Col>
                          </Row>
                          {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                        </CardBody>
                      </Card>
                    </Link>
                  }
                </Col>
                <Col lg="6" xl="3">
                  {
                    <Link to="/admin/view-teams">
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                Teams
                              </CardTitle>
                              {data && (
                                <span className="h2 font-weight-bold mb-0">
                                  {data.teams.length}
                                </span>
                              )}
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                <i className="fas fa-users" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Link>
                  }
                </Col>
                <Col lg="6" xl="3">
                  <Link to="/admin/view-all-riders">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Riders
                            </CardTitle>
                            {riders && (
                              <span className="h2 font-weight-bold mb-0">
                                {riders.riders.length}
                              </span>
                            )}
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                              <i className="fas fa-users" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg="6" xl="3">
                  <Link to="/admin/view-all-horses">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Horses
                            </CardTitle>
                            {horses && (
                              <span className="h2 font-weight-bold mb-0">
                                {horses.horses.length}
                              </span>
                            )}
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                              <FontAwesomeIcon icon={faHorseHead} />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              </Row>
          </div>
        </Container>
      </div>}
    </>
  );
};

export default Header;
