import React, { useState, useEffect } from 'react';
import { useAuthContext } from 'hooks/useAuthContext';
import { useFetch } from 'hooks/useFetch';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap';

const AdminNavbar = (props) => {
  const { dispatch, user } = useAuthContext();
  const [pendingMigrationsCount, setPendingMigrationsCount] = useState(0);

  const fetchPendingMigrationsCount = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_NODE_SERVER + '/api/migrate-horse/approvalPendingCount'
      );
      const data = await response.json();
      setPendingMigrationsCount(data.pendingMigrationsCount);
    } catch (error) {
      console.error('Error fetching pending migrations count:', error);
    }
  };

  useEffect(() => {
    fetchPendingMigrationsCount();
    const intervalId = setInterval(fetchPendingMigrationsCount, 10000);
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <>
      <Navbar className="navbar-top bg-gradient-info navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block" to="/">
            {props.brandText}
          </Link>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form>
          <Link to={'/admin/notifications'} style={{ position: 'relative' }}>
            <span style={{ marginRight: '40px', position: 'relative' }}>
              <i className="fa fa-bell text-white" style={{ fontSize: '22px', color: '#fff' }}></i>
              {pendingMigrationsCount > 0 && (
                <span
                  className="badge rounded-pill badge-notification bg-danger text-white"
                  style={{ position: 'absolute', right: '-13px', top: '-10px', padding: '3px 7px' }}
                >
                  {pendingMigrationsCount}
                </span>
              )}
            </span>
          </Link>

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span>
                    <i className="ni ni-circle-08" style={{ fontSize: '25px' }}></i>
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user.f_name} {user.l_name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={handleLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
