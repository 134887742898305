import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import EnrollRider from "pages/captain/EnrollRider";
import React, { useEffect, useState } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

const EnrollRiderAdmin = () => {
  const { showId, eventId, subEventId, eventCollectionId } = useParams();
  const [team, setTeam] = useState({
    teamId: "",
    teamName: "",
  });

  const [rider, setRider] = useState({
    riderId: "",
    riderName: "",
  });

  const [horse, setHorse] = useState({
    horseId: "",
    horseName: "",
  });
  const [ ridersList, setRidersList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [riderCategory,setRiderCategoryId] = useState("");
  const {
    data: teamList,
    isPending,
    error,
  } = useFetch(process.env.REACT_APP_NODE_SERVER + "/api/team/getAll");
  const [payload, setPayload] = useState('');

  const {isPending:eventCollectionPending,error:eventCollectionError,data:event_collectionList} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/event-collection/getEventCollectionById/'+eventCollectionId);
  const { postData, resData, postError } = usePost();

  useEffect(()=>{
    if (event_collectionList && event_collectionList.eventCollection) {
      setCategories(JSON.parse(event_collectionList.eventCollection.category_ids));
    }
  }, [event_collectionList])
  
  // useEffect(() => { 
  //   if (categories.length > 0) {
  //     setPayload('?category_ids='+categories.join(','));
  //   }
  // },[categories])
  
  const { data: allTeamrRiders } = useFetch(
    process.env.REACT_APP_NODE_SERVER + "/api/rider/getAll/" + team.teamId
  );

  const { data: horses } = useFetch(
    process.env.REACT_APP_NODE_SERVER + "/api/horse/getAll/" + team.teamId 
  );


  useEffect(() => {
    if (allTeamrRiders && allTeamrRiders.riders) {
      setRidersList(allTeamrRiders.riders);
    }
   },[allTeamrRiders])
  const fetchRider = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_NODE_SERVER
          }/api/rider/getAllRiders/${team.teamId}?category_ids=${categories.join(
            ","
          )}`
        );
        if (!response.ok) {
          throw new Error("Could not connect to the endpoint!");
        }
        const riders = await response.json();
        console.log(riders)
        if (riders.status !== 200) {
          throw new Error("Unable to fetch rider!");
        }
        //console.log(riders)
        setRidersList(riders?.riders);
      } catch (err) {
        NotificationManager.error(err.message, "Error", 3000);
      }
    };
    useEffect(() => {
      if (categories && team.teamId) {
        fetchRider();
      }
      console.log(categories)
    }, [categories, team]);

  const handleTeamChange = (e) => {
    const [teamId, teamName] = e.target.value.split(":");
    setTeam({ teamId, teamName });
  };

  const handleRiderChange = (e) => {
    const [riderId, riderName] = e.target.value.split(":");
    const selectedOptions = e.target.selectedOptions[0]
    const category_id = selectedOptions.getAttribute("data");
    setRider({ riderId, riderName });
    setRiderCategoryId(category_id)
  };

  const handleHorseChange = (e) => {
    const [horseId, horseName] = e.target.value.split(":");
    setHorse({ horseId, horseName });
  };

  const EnrollRider = () => { 
    if (team.teamId === "" || rider.riderId === "" || horse.horseId === "") {
      NotificationManager.error("Please select all fields", "Error", 3000);
      return false;
    }
    const payload = {
      rider_id: rider.riderId,
      category_id: riderCategory,
      horse_id: horse.horseId,
      team_id: team.teamId,
      show_id: showId,
      eventcollection_id: eventCollectionId,
      event_id: eventId,
      subevent_id: subEventId,
      archive: 0,
      waitlist: 0 ,
      hc: false,
      post_entry: 1,
      created_at: new Date(),
      updated_at: new Date(),
    };
    //console.log(payload)
    postData(
      process.env.REACT_APP_NODE_SERVER + "/api/enrollment/add-enrollment",
      payload,
      "POST"
    );
  }


  useEffect(() => {
    if (resData && resData.status === 200) {
      
      NotificationManager.success(
        "Rider Enrolled Successfuly!",
        "Success",
        3000
      );
      requestFormReset()
      }
      if (postError) {
        NotificationManager.error("Please select a horse", "Error", 3000);
      }
  }, [resData, postError]);
  const requestFormReset = () => {
    setRider({
      riderId: "",
      riderName: "",
    })
    setHorse({
      horseId: "",
      horseName: "",
    })
    setRiderCategoryId("")
    setRidersList([])
    setTeam({
      teamId: "",
      teamName: "",
    })
  }
  return (
    <div className="container pt-8">
      <div className="row">
        <div className="col">
          <h3 className="mb-0">Enroll Rider (THIS FOR ONLY POST ENTRY)</h3>
        </div>
      </div>
      <div className="row">
        <NotificationContainer />
        <Col lg="12" md="12" sm="12">
          <Card>
            <CardBody>
              <FormGroup className="mb-3 inputWithLabel">
                <Label className="input_label">Select Team: </Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Team"
                    type="select"
                    value={`${
                      team?.teamId != ""
                        ? team?.teamId + ":" + team?.teamName
                        : ""
                    }`}
                    onChange={handleTeamChange}
                  >
                    <option value="">Select Team</option>
                    {teamList &&
                      teamList.teams.map((team, key) => (
                        <option
                          value={`${team.team_id}:${team.team_name}`}
                          key={team.team_id}
                        >
                          {team.team_name}
                        </option>
                      ))}
                  </Input>
                </InputGroup>
              </FormGroup>
              { team.teamId &&
                <Row>
                  {ridersList && (
                    <Col lg="6" md="6" sm="12">
                      <FormGroup className="mb-3">
                        {/* <Label className="input_label">Select Rider</Label> */}
                        <InputGroup className="input-group-alternative">
                          <Input
                            placeholder="Rider"
                            type="select"
                            value={`${
                              rider?.riderId != ""
                                ? rider?.riderId + ":" + rider?.riderName
                                : ""
                            }`}
                            onChange={(e)=>handleRiderChange(e)}
                          >
                            <option value="">Select Rider</option>
                            {ridersList.map((rider) => {
                              return (
                                <option
                                  value={`${rider.rider_id}:${rider.rider_name}`}
                                  key={rider.rider_id}
                                  data={rider.category_id}
                                >
                                  {rider.rider_name}
                                </option>
                              );
                            })}
                          </Input>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  )}
                  <Col lg="6" md="6" sm="12">
                    <FormGroup className="mb-3 inputWithLabel">
                      {/* <Label className="input_label">Select Horse: </Label> */}
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Horse"
                          type="select"
                          value={`${
                            horse?.horseId != ""
                              ? horse?.horseId + ":" + horse?.horseName
                              : ""
                          }`}
                          onChange={handleHorseChange}
                        >
                          <option value="">Select Horse</option>
                          {horses &&
                            horses.horses.map((horse) => {
                              return (
                                <option
                                  value={`${horse.horse_id}:${horse.name}`}
                                  key={horse.horse_id}
                                >
                                  {horse.name}
                                </option>
                              );
                            })}
                        </Input>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="12" md="12" sm="12" className="text-center">
                    <Button color="primary" onClick={() => {
                      EnrollRider()
                    }}>
                          Enroll Rider
                    </Button>
                  </Col>
                </Row>
              }
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default EnrollRiderAdmin;
